import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {StorageService} from "../services";

@Injectable()
export class CurrentOrderInterceptor implements HttpInterceptor {
    constructor(private storage: StorageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Verifica si la URL contiene /current-order o /refresh-current-order
        if (req.method == 'GET' && (req.url.includes('/current-order') || req.url.includes('/refresh-current-order'))) {

            console.log('CurrentOrderInterceptor: intercepting request to /current-order or /refresh-current-order');

            return next.handle(req).pipe(
                // NSMZ: Revisa si la respuesta es un HttpResponse, para usarla luego
                tap((event) => {
                    if (event instanceof HttpResponse) {
                        const response = event.body;

                        // NSMZ: Se setea el flag is_tooltip_visible solo si hay productos en la current order, o hay ordenes confirmadas
                        // o hay ordenes pendientes de confirmacion
                        this.storage.set('is_tooltip_visible', `${
                            response.data.order_products.length > 0 
                            || response.data.total_created_orders > 0 
                            || response.data.total_pending_products > 0}`
                        );
                        this.storage.set('has_in_progress_orders', response.data.has_in_progress_orders);
                    }
                })
            );
        }

        // Si no coincide con /current-order o /refresh-current-order, sigue con la solicitud
        return next.handle(req);
    }
}
