import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralService } from '../general/general.service';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends GeneralService<any> {

  constructor(protected http: HttpClient){
    super(http, 'me');
  }

  me(): Observable<any> {
    return this.getPrivate();
  }
}
