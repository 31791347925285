import { Injectable } from '@angular/core';
import { Subscription, timer, Observable, interval, BehaviorSubject } from 'rxjs';
import { map  } from 'rxjs/operators';
import { InviterInviteOptions, InviterOptions } from 'sip.js';
import { OutgoingRequestDelegate } from 'sip.js/lib/core';
import { SimpleUser, SimpleUserDelegate, SimpleUserOptions } from 'sip.js/lib/platform/web';
import { RING_FILE, BUSY_FILE, PH_ST_REGISTERED, PH_ST_UNREGISTERED, SE_ST_ACCEPTED, SE_ST_REJECTED, SF_ST_WAITING_FOR_USER, SF_ST_DIALING, PH_ST_CONNECTED, PH_ST_DISCONNECTED } from './callback-session.config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallbackSessionService {

    onCallReceived = new Subject<any>();

    onCallStatusChanges = new BehaviorSubject<boolean>(false);
    phoneStatusChanges = new BehaviorSubject<string>(PH_ST_DISCONNECTED);
    callStateChanges = new BehaviorSubject<string>(SF_ST_WAITING_FOR_USER);
    elapsedTimeChanges = new BehaviorSubject<string>('00:00');
    private simpleUser: SimpleUser;
    private ringing: any = new Audio(RING_FILE);
    private busy: any = new Audio(BUSY_FILE);
    private voice: any = null;
    private onPhoneScreen = false;
    private extension = '';
    private conf = null;
    private chronometer: Subscription;

    public isPristine() {
        return !this.simpleUser;
    }

    initialize(device: any){

        this.setOnCall(false);
        this.setPhoneStatus(PH_ST_DISCONNECTED);
        this.setCallState(SF_ST_WAITING_FOR_USER);

        const simpleDelegate: SimpleUserDelegate = {
            onServerConnect: () => { this.setPhoneStatus(PH_ST_CONNECTED); },
            onRegistered: () => { console.log('Successfully registered'); this.setPhoneStatus(PH_ST_REGISTERED); },
            onUnregistered: () => { this.setPhoneStatus(PH_ST_UNREGISTERED); },
            onServerDisconnect: () => { this.setPhoneStatus(PH_ST_DISCONNECTED); },

            onCallCreated: () => { 
                this.setOnCall(true);
                this.setCallState(SF_ST_DIALING) 
            }, 
            onCallAnswered: () => { 
                this.setOnCall(true);
                this.setCallState(SE_ST_ACCEPTED);
                this.ringing.pause();
                this.chronometer = this.createCronometer().subscribe(([minutes, seconds]) => {
                    this.elapsedTimeChanges.next((minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
                });
            },
            onCallHangup: () => {
                this.setOnCall(false);
                this.setCallState(SF_ST_WAITING_FOR_USER);
                this.ringing.pause();
                this.elapsedTimeChanges.next('00:00');
                if (this.chronometer) {
                    this.chronometer.unsubscribe();
                }
            },
         }

        const options: SimpleUserOptions = {
            aor: 'sip:' + device.callback[0].user + '@' + this.extractDomain(device.callback[0].sip_server),
            delegate: simpleDelegate,
            userAgentOptions: {
                userAgentString: 'contactless',
                displayName: device.guest + '-' + device.room,
                authorizationUsername: device.callback[0].user,
                authorizationPassword: device.callback[0].password
            },
            reconnectionAttempts: 3,
            reconnectionDelay: 10,
            media: {
                remote: {
                    audio: new Audio()
                }
            }
        };
        this.simpleUser = new SimpleUser(device.callback[0].sip_server, options);
        this.simpleUser.register()
    }

    public destroy() {
        this.stop();
    }

    public async start() {
        if(this.simpleUser && !this.simpleUser.isConnected())
            await this.simpleUser.connect()
    }

    public async stop() {
        if(this.simpleUser && this.simpleUser.isConnected())
            await this.simpleUser.disconnect()
    }

    public async dial(extension: string, device: any) {

        if(!extension) return;

        this.extension = extension;

        const aor = `sip:${extension}@${this.extractDomain(device.callback[0].sip_server)}`;
        
        const requestDelegate: OutgoingRequestDelegate = {
            onReject: () => {
                this.ringing.pause();
                this.busy.play();
                timer(5000).subscribe(() => {
                    this.busy.pause();
                    this.setOnCall(false);
                    this.setCallState(SE_ST_REJECTED);
                });
            }
        }

        const inviterOptions: InviterOptions = {
            extraHeaders: [
                'gueststatus: 1',
                'guestname: ' + device.guest,
                'guestroom: ' + device.room
            ]
        }

        const inviterInviteOptions: InviterInviteOptions = {
            requestDelegate: requestDelegate
        }

        await this.simpleUser.call(aor, inviterOptions, inviterInviteOptions)
    }

    public dmtf(code: string) {
        this.simpleUser.sendDTMF(code);
    }

    public isMuted() {
        return this.simpleUser.isMuted();
    }

    public mute() {
        this.simpleUser.mute();
    }

    public unmute() {
        this.simpleUser.unmute();
    }

    public hang() {
        if(this.simpleUser) {
            this.simpleUser.hangup();
        }
    }

    public isOnPhoneScreen() {
        return this.onPhoneScreen;
    }

    public setOnPhoneScreen(onPhoneScreen: boolean) {
        this.onPhoneScreen = onPhoneScreen;
    }

    public getLastExtension() {
        return this.extension;
    }

    public changeOutputDevice(deviceId: string) {
        if(this.voice) if(this.voice.sinkId !== undefined) this.voice.setSinkId(deviceId);
        if(this.ringing.sinkId !== undefined) this.ringing.setSinkId(deviceId);
        if(this.busy.sinkId !== undefined) this.busy.setSinkId(deviceId);
    }

    private createCronometer(): Observable<number[]>{
        return interval(1000).pipe(map(tick => {
            const seconds = tick % 60;
            const minutes = Math.round(tick / 60);
            return [minutes, seconds];
        }));
    }

    private setPhoneStatus(phoneStatus: string) {
        this.phoneStatusChanges.next(phoneStatus);
    }

    private setCallState(callState: string) {
        this.callStateChanges.next(callState);
    }

    private setOnCall(onCall: boolean) {
        this.onCallStatusChanges.next(onCall);
    }    

    private extractDomain(url: string) {
        let domain: string;
        if (url.indexOf('://') > -1) {
            domain = url.split('/')[2];
        } else {
            domain = url.split('/')[0];
        }
        return domain.split(':')[0];
    }
}
