import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from '../general/general.service';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class WaiterService extends GeneralService<any> {

  constructor(
    protected http: HttpClient,
    protected storage: StorageService
  ){
    super(http, 'orders');
  }

  assistanceRequest() {
    const token = this.storage.get(ConstantsService.MY_STAY_TOKEN);
    const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Bill-diner-token': token
    });
    return this.http.post<ApiResponse<any>>(`${this.getApiPath()}/restaurant-stays/request-waiter-assistance?language_code=${languageCode}`,{},{headers: this.headers});
  }

  newOrderRequest() {
    const token = this.storage.get(ConstantsService.MY_STAY_TOKEN);
    const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Bill-diner-token': token
    });
    return this.http.post<ApiResponse<any>>(`${this.getApiPath()}/orders/notify-new-diner-order?language_code=${languageCode}`,{},{headers: this.headers});
  }
}
