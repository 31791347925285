import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConnectToBackend, ErrorHandler } from './interceptors';
import { APP_BASE_HREF } from '@angular/common';
import { AcronymService } from './services/acronym/acronym.service';
import { StorageService } from './services/storage/storage.service';
import { ReservationGuard } from './guards/reservation-guard';
import { SoftphoneSessionService } from './services/softphone/softphone-session.service';
import { SoftphonePinComponent } from './contact/softphone-pin/softphone-pin.component';
import { RouteRewriteRulesProvider } from './initialization';
import {AngularFireModule} from '@angular/fire';
import {config} from '../assets/firebase-config';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import { CallbackPinComponent } from './contact/callback-pin/callback-pin.component';
import { CallbackCallIncomingComponent } from './contact/callback-call-incoming/callback-call-incoming.component';
import { CallbackConfigWithCredentialsResolver } from './resolves/callback-config-with-credentials-resolver';
import { CallbackPinIncomingComponent } from './contact/callback-pin-incoming/callback-pin-incoming.component';
import {CurrentOrderInterceptor} from "./interceptors/current-order.interceptor";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function routeRewriteRulesProviderFactory(provider: RouteRewriteRulesProvider) {
  return () => provider.load();
}

export const dinamicHref = {
  provide: APP_BASE_HREF,
    useValue: '/' + (window.location.pathname.split('/')[1] || '')
};

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ConnectToBackend, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CurrentOrderInterceptor, multi: true}
];

export const initialDataProviders = [
  { provide: APP_INITIALIZER, useFactory: routeRewriteRulesProviderFactory, deps: [RouteRewriteRulesProvider], multi: true },
];

@NgModule({
  declarations: [
      AppComponent,
      SoftphonePinComponent,
      CallbackPinComponent,
      CallbackPinIncomingComponent,
      CallbackCallIncomingComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(config),
    AngularFireMessagingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    dinamicHref,
    AcronymService,
    StorageService,
    SoftphoneSessionService,
    ReservationGuard,
    interceptorProviders,
    initialDataProviders,
    RouteRewriteRulesProvider,
    CallbackConfigWithCredentialsResolver,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
