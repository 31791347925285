import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallbackStorageService } from '../services/callback/callback-storage.service';
import { CallbackSessionService } from '../services/callback/callback-session.service';

@Injectable()
export class CallbackConfigWithCredentialsResolver implements Resolve<any[]> {

  constructor(private callbackService: CallbackStorageService, 
              private callbackSessionService: CallbackSessionService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.callbackService.getConfig(this.callbackSessionService.isPristine()).pipe(map(response => {
        return response.data;
    }));
  } 
}
