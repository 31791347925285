// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  configFirebase: {
    apiKey: "AIzaSyAZcSaCBbz6nYBjFcF4-ImnmSjmzOfumi0",
    authDomain: "newagent-baqiob.firebaseapp.com",
    databaseURL: "https://newagent-baqiob.firebaseio.com",
    projectId: "newagent-baqiob",
    storageBucket: "newagent-baqiob.firebasestorage.app",
    messagingSenderId: "223668320381",
    appId: "1:223668320381:web:a8cbb86b4b074699855914"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
