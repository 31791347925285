import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CallbackSessionIncomingService } from 'src/app/services/callback/callback-session-incoming.service';
import { RenderService } from 'src/app/services/callback/render.service';

@Component({
  selector: 'app-callback-pin-incoming',
  templateUrl: './callback-pin-incoming.component.html',
  styleUrls: ['./callback-pin-incoming.component.scss'],
})
export class CallbackPinIncomingComponent {

  private onCall = false; 

  constructor(private router: Router, private callbackSessionIncomingService: CallbackSessionIncomingService,private renderService: RenderService) {
    this.callbackSessionIncomingService.onCallStatusChanges.subscribe(onCall => this.onCall = onCall);
  }

  visible() {

    if(this.callbackSessionIncomingService.isOnPhoneScreen()) {      
        return false;
    } else {
        if(!this.onCall) {
            this.callbackSessionIncomingService.destroy();
        }
        
         if (this.renderService.visiblePin && (this.renderService.rendered !== this.renderService.renderedC2)) {
              return false;
         }

        return this.onCall;
    }
  }

  return() {
    this.router.navigate(["contact/callback-incoming"], {
      queryParams: { view: true },
    });
  }

  hang() {
    this.router.navigate(["contact/callback-incoming"], {
      queryParams: { hangV: true },
    });
  }
}