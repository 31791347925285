import { Order } from '../order/order';

export class CurrentOrder extends Order {
  static initCurrentOrder(providerName: string, currency: string, totalCreatedOrder: number, orderProducts = []) {
	  return new CurrentOrder(currency, providerName, totalCreatedOrder, orderProducts);
  }


	// Analizar si este modelo sigue teniendo sentido
	constructor(currency: string, providerName: string, totalCreatedOrders: number,  products = [], notes = '') {
		super(currency, providerName, products, notes, totalCreatedOrders);
	}
}