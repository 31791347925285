import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RenderService {
  private isRendered = true;
  private isRenderedC2 = true;
  private isRenderedPin = false;

  public get rendered(): boolean {
    return this.isRendered;
  }

  public get renderedC2(): boolean {
    return this.isRenderedC2;
  }

  public get visiblePin(): boolean {
    return this.isRenderedPin;
  }

  public setRendered(state: boolean): void {
    this.isRendered = state;
  }

  public setRenderedC2(state: boolean): void {
    this.isRenderedC2 = state;
  }

  public setVisiblePin(state: boolean): void {
    this.isRenderedPin = state;
  }

}