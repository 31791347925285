import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CallbackSessionService } from 'src/app/services/callback/callback-session.service';

@Component({
  selector: 'app-callback-pin',
  templateUrl: './callback-pin.component.html',
  styleUrls: ['./callback-pin.component.scss'],
})
export class CallbackPinComponent {

  private onCall = false; 

  constructor(private router: Router, private callbackSessionService: CallbackSessionService) {
    this.callbackSessionService.onCallStatusChanges.subscribe(onCall => this.onCall = onCall);
  }

  visible() {

    if(this.callbackSessionService.isOnPhoneScreen()) {      
        return false;
    } else {
        if(!this.onCall) {
            this.callbackSessionService.destroy();
        }
        return this.onCall;
    }
  }

  return() {
    this.router.navigate(['contact/callback-main']);
  }

  hang() {
    this.callbackSessionService.hang();
    this.router.navigate(['contact/hang-callback']);
  }
}