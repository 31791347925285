import { OrderState } from '../order-state/order-state';
import { ProviderType } from '../provider-type/provider-type';
import { Provider } from '../provider/provider';
import { Order } from '../order/order';
import {OrderProductsByOwner} from "../order-products-by-owner/order-products-by-owner";

export class ProviderOrder extends Order {
	//Datos desde backend
	public id: number;
	public property_id: number;
	public order_state_id: number;
	public provider_type: ProviderType;
	public order_state: OrderState;
	public updated_at: string;
	public created_at: string;
	public code: string;

	//Ambos
	public provider: Provider;
	public delivery_date: string;

	//Para manejo de frontned
	public paymentTypeId: number;
	public deliveryDate: Date;

	constructor(providerName: string, currency: string,  totalCreatedOrders = 0, products = [], notes = '') {
		super(currency, providerName, products, notes, totalCreatedOrders);
		this.clear();
	}

	static initOrder(providerId: number, providerName: string, currency: string, orderProducts = [], totalCreatedOrders: number = 0, totalPendingProducts: number = 0) {
		const instance = new ProviderOrder(providerName, currency, totalCreatedOrders, orderProducts);
		instance.provider.id = providerId;
		instance.provider.name = providerName;
		instance.totalPendingProducts = totalPendingProducts;
		return instance;
	}

	static initBasicOrder(providerName: string, currency: string) {
		const instance = new ProviderOrder(currency, providerName);
		instance.providerName = providerName;
		instance.currency = currency;

		return instance;
	}

	public clear() {
		this.provider_type = new ProviderType();
		this.provider = new Provider();
		this.delivery_date = null;
	}
}