import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GeneralService} from '../general/general.service';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenericProvidersService extends GeneralService<any>{

  public genericProvidersByCategory: any;
  constructor(protected http: HttpClient){
    super(http, 'provider-categories/get-all-with-generics');
  }

  public getByProviderCategorySlug(slug: string){
    return this.genericProvidersByCategory[slug];
  }

  public getUniqueGenericProvider(providerCategorySlug: string, genericProviderSlug: string){
    let genericProvider = this.genericProvidersByCategory[providerCategorySlug];
    if(!genericProvider) throw `No Generic Provider found for category ${providerCategorySlug}`;
    return genericProvider.find( genericProvider => genericProvider.slug === genericProviderSlug)
  }

  public getTemplateBySlug(providerCategorySlug: string, genericProviderSlug: string) {
    try {
      return this.getUniqueGenericProvider(providerCategorySlug, genericProviderSlug).template;
    } catch (e) {
      console.log(e);
    }
  }

  getAll() {
    let data;
    if (this.genericProvidersByCategory){
      data = this.genericProvidersByCategory;
    }else{
      data = this.getWithExtraUrlAndParams('', '');
      data.subscribe( response => {
        this.genericProvidersByCategory = response.data;
      });
    }
    return data;
  }
}
