import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IOptionsType } from "../../interfaces/softphone/softphone-interfaces";
import { CallbackStorageService } from "src/app/services/callback/callback-storage.service";
import { CallbackSessionIncomingService } from "src/app/services/callback/callback-session-incoming.service";
import { LoadingService, StorageService } from "src/app/services";
import { map, distinctUntilChanged } from "rxjs/operators";
import {
  SE_ST_PROGRESS,
  SE_ST_ACCEPTED,
  SE_ST_CANCELED,
  SE_ST_TERMINATED,
  SE_ST_FAILED,
  SE_ST_REJECTED,
  SE_ST_BYE,
  SE_ST_DTMF,
  PH_ST_REGISTERED,
  SF_ST_WAITING_FOR_USER,
  SF_ST_DIALING,
  PH_ST_REGISTRATIONFAILED,
  T_ST_ERROR,
  PH_ST_CONNECTED,
  PH_ST_UNREGISTERED,
  PH_ST_DISCONNECTED,
} from "src/app/services/softphone/softphone-session.config";
import { Subscription } from "rxjs";
import { InitialSetup } from "../../models";
import { DevicesService } from "src/app/services/devices/devices.service";
import { CallbackConfigWithCredentialsResolver } from "src/app/resolves/callback-config-with-credentials-resolver";
import { RouterStateSnapshot } from "@angular/router";
import { AnimationController } from "@ionic/angular";
import { RenderService } from 'src/app/services/callback/render.service';

@Component({
  selector: "app-callback-call-incoming",
  templateUrl: "./callback-call-incoming.component.html",
  styleUrls: ["./callback-call-incoming.component.scss"],
})
export class CallbackCallIncomingComponent {
  public config: IOptionsType;
  public device: any;
  public callState = "";
  public phoneStatus = "";
  public subscriptions: Subscription[] = [];
  public initialSetup: InitialSetup;
  public visibleC: boolean = false;
  public phoneStatusMessages: Map<string, string>;
  public callStatesMessages: Map<string, string>;
  public initialSetupGet: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private callbackService: CallbackStorageService,
    private callbackSessionIncomingService: CallbackSessionIncomingService,
    private loadingService: LoadingService,
    private devicesService: DevicesService,
    private callbackConfigResolver: CallbackConfigWithCredentialsResolver,
    private animationCtrl: AnimationController,
    public storage: StorageService,
    private renderService: RenderService
  ) {

    this.storage.set("statusConectionCallback", PH_ST_DISCONNECTED);
    /**
     * Map de estados del telefono para traducir.
     */
    this.phoneStatusMessages = new Map<string, string>();
    this.phoneStatusMessages.set(PH_ST_CONNECTED, "SOFTPHONE.CONNECTED");
    this.phoneStatusMessages.set(PH_ST_REGISTERED, "SOFTPHONE.REGISTERED");
    this.phoneStatusMessages.set(PH_ST_UNREGISTERED, "SOFTPHONE.UNREGISTERED");
    this.phoneStatusMessages.set(PH_ST_DISCONNECTED, "SOFTPHONE.DISCONNECTED");
    this.phoneStatusMessages.set(
      PH_ST_REGISTRATIONFAILED,
      "SOFTPHONE.REGISTRATION_FAILED"
    );
    this.phoneStatusMessages.set(T_ST_ERROR, "SOFTPHONE.TRANSPORT_ERROR");

    /**
     * Map de estados de la llamada para traducir.
     */
    this.callStatesMessages = new Map<string, string>();
    this.callStatesMessages.set(SF_ST_WAITING_FOR_USER, "SOFTPHONE.WAITING");
    this.callStatesMessages.set(SF_ST_DIALING, "SOFTPHONE.DIALING");
    this.callStatesMessages.set(SE_ST_PROGRESS, "SOFTPHONE.STARTED_CALL");
    this.callStatesMessages.set(SE_ST_ACCEPTED, "SOFTPHONE.STARTED_CALL");
    this.callStatesMessages.set(SE_ST_CANCELED, "SOFTPHONE.ENDED_CALL");
    this.callStatesMessages.set(SE_ST_TERMINATED, "SOFTPHONE.ENDED_CALL");
    this.callStatesMessages.set(SE_ST_FAILED, "SOFTPHONE.ENDED_CALL");
    this.callStatesMessages.set(SE_ST_REJECTED, "SOFTPHONE.ENDED_CALL");
    this.callStatesMessages.set(SE_ST_BYE, "SOFTPHONE.ENDED_CALL");
    this.callStatesMessages.set(SE_ST_DTMF, "SOFTPHONE.ENDED_CALL");

    /**
     * Valida que este logueado
     */ 
    if(this.storage.get('token', '') == ''){
      return;
    }

    /**
     * Device
     */
    this.devicesService.me().subscribe((response) => {
      this.device = response.data;

      if (this.device && this.device.callback[0]) {

        /**
         * Avisamos que estamos en el componente del softphone
         */
        this.callbackSessionIncomingService.setOnPhoneScreen(true);

        /**
         * Si es la primera vez que entra al componente, instanciamos la libreria.
         */
        if (this.callbackSessionIncomingService.isPristine()) {
          this.callbackSessionIncomingService.initialize(
            this.device
          );
        }

        this.callbackSessionIncomingService.start();

        /**
         * Sirve para obtener el estado del telefono con respecto a la central y a las llamadas.
         */
        const subPhoneStatusChanges =
          this.callbackSessionIncomingService.phoneStatusChanges
            .pipe(distinctUntilChanged())
            .subscribe((status) => {
              this.storage.set("statusConectionCallback", status);
              if (this.device.callback[0])
                this.callbackService
                  .log("P", this.device.callback[0].user, status)
                  .subscribe();
              this.phoneStatus = this.phoneStatusMessages.has(status)
                ? this.phoneStatusMessages.get(status)
                : status;
            });

        this.subscriptions.push(subPhoneStatusChanges);

        const subCallStateChanges =
          this.callbackSessionIncomingService.callStateChanges
            .pipe(distinctUntilChanged())
            .pipe(
              map((callState) => {
                if (this.device.callback[0])
                  this.callbackService
                    .log("C", this.device.callback[0].user, callState)
                    .subscribe();

                    if (this.renderService.renderedC2) {
                      this.renderService.setRendered(false); 
                      this.renderService.setRenderedC2(true);
                    }

                    if (callState === SF_ST_DIALING) {
                      this.visibleC = true;
                      this.renderService.setVisiblePin(this.visibleC);
                      setTimeout(() => {
                        this.animateCard();
                      }, 1);
                    }

                    if (callState === SF_ST_WAITING_FOR_USER) {
                      this.visibleC = false;
                      this.renderService.setVisiblePin(this.visibleC);
                    }

                    return this.callStatesMessages.has(callState)
                      ? this.callStatesMessages.get(callState)
                      : "";
              })
            )
            .subscribe((callState) => (this.callState = callState));

        this.subscriptions.push(subCallStateChanges);
      }

    });

    /**
     * Configuracion del resolver
     */
    const route = this.activatedRoute.snapshot;
    const state: RouterStateSnapshot = this.router.routerState.snapshot;

  }

  animateCard() {
    const animation = this.animationCtrl
      .create()
      .addElement(document.querySelector("ion-card"))
      .duration(500)
      .fromTo("transform", "translateY(-100%)", "translateY(0)")
      .fromTo("opacity", "0", "1");

    animation.play();
  }

  async acceptIncomingCall() {

    /**
    * Valida si tiene permisos (configs->allow_callback). 
    */       
    const data = this.storage.get('initialSetupGet');
    if (data) {
      const initialSetupData = JSON.parse(data);

      if(!initialSetupData.allow_callback){

        this.visibleC = false;
        this.renderService.setVisiblePin(this.visibleC);
        this.callbackSessionIncomingService.hangupCall();
        
        setTimeout(()=>{
          this.router.navigate(['contact/enabled-callback']);
          return;
        }, 5);
      
      }else{

        this.visibleC = false;
        this.renderService.setVisiblePin(this.visibleC);
        this.callbackSessionIncomingService.acceptCall();
    
        this.router.navigate(["contact/callback-incoming"], {
          queryParams: { view: true },
        });

        const loading = await this.loadingService.getLoadingIndicator(
          "SOFTPHONE.CONECTING_CALL"
        );
    
        setTimeout(() => {
          loading.dismiss();
        }, 5000);

      }

    }

  }

  hangupCall() {
    this.visibleC = false;
    this.renderService.setVisiblePin(this.visibleC);
    this.callbackSessionIncomingService.hangupCall();
    this.router.navigate(["contact/hang-callback"]);
  }
}