import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class CallbackStorageService extends GeneralService<any> {

  constructor(protected http: HttpClient) {
    super(http, 'callback/config');
  }

  log(logt: string, userc: string, logm: string): Observable<any>{
    return this.http.post<any>(`${this.getPrivateApiPath()}/callback/log`, {type: logt, extension: userc, message: logm});
  }
  
  getConfig(withCredentials: boolean): Observable<any>{
    return this.http.get<any>(`${this.getPrivateApiPath()}/callback/config/${+withCredentials}`);
  }
  
  setTokenNotification(deviceid: any, token: any, os: string): Observable<any>{
    return this.http.post<any>(`${this.getPrivateApiPath()}/sofphone/tokennotification`, {device_id: deviceid, token: token, os: os});
  }

  setSentNotification(id: any): Observable<any>{
    return this.http.post<any>(`${this.getPrivateApiPath()}/sofphone/setSentNotification`, {id: id});
  }
}
