import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConstantsService} from '../services';
import {ComandaErrorHandlerService} from "../services/errors/comanda-error-handler.service";

@Injectable()
export class ErrorHandler implements HttpInterceptor {

  constructor(
    private router: Router,
    private comandaErrorHandlerService: ComandaErrorHandlerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 0 || err.status === 429) {
          this.router.navigate(['/errors/something-went-wrong']);
        } else if (err.status === 406 || err.status === 410 || err.status === 402) { // Son los status code utilizados para identificar bill closed, restaurant stay closed y producto premium a bill all inclusive respectivamente.
          return next.handle(request);
        } else if (err.status === 403) {
            if (err.url.includes('current-order')) {
                return throwError(err);
            }
            this.router.navigateByUrl('/landing/still-pending');
        } else if(err.status == 412) { // Status code utilizado para identificar cuando una cuenta tiene productos y se esta intentando bloquear
            return next.handle(request);
        } else if(err.status == 423) { // Status code utilizado para identificar cuando una cuenta esta bloqueada
            this.comandaErrorHandlerService.handleCurrentBillIsLocked(err.error.data.data);
            next.handle(request);
        } else if(err.status == 404) {
            return next.handle(request);
        } else if (err.status !== 400 && err.status !== 409 && err.status !== 425 && err.status !== 500) {
          this.router.navigate(['/errors/return-to-home']);
        } else if (err.error.data?.message === ConstantsService.PROPERTY_NOT_FOUND_INDEX) {
          this.router.navigate(['/errors/property-not-found']);
        }
        return throwError(err);
      })
    );
  }
}