import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Order, OrderProduct, ProviderOrder, Restaurant} from '../../models';
import {ProvidersService} from '../providers/providers.service';
import {ModalService} from '../modal/modal.service';
import {CurrentOrderService} from '../current-order/current-order.service';
import {Router} from '@angular/router';
import {BackService} from '../back/back.service';
import {ConstantsService} from '../constants/constants.service';
import {InitialSetupService} from '../initial-setup/initial-setup.service';
import {RestaurantsService} from '../restaurants/restaurants.service';

@Injectable({
    providedIn: 'root'
})
export class ManageErrorsHelperService {

    private backUrl: string;

    constructor(private router: Router,
                private providersService: ProvidersService,
                private modalService: ModalService,
                private currentOrderService: CurrentOrderService,
                private backService: BackService,
                private initialSetupService: InitialSetupService,
                private restaurantService: RestaurantsService) {
        this.backService.backUrl.subscribe(backUrl => {
            this.backUrl = backUrl;
        });
    }

    public catchProductNotFoundAndOrderCreatedErrors(providerIndex: string, providerName: string, order: Order, orderProduct: OrderProduct, updateOrderInProvider: boolean = true, onDeleteProductCallbackFn: any = null, onCreateOrderCallbackFn: any = null) {
        this.currentOrderService.getCurrentOrder().subscribe( (response: any) => {
            if(response.data.total_created_orders > order.totalCreatedOrders) {
                this.modalService.notification('ERROR.INVALID_ACTION_HEADER', 'ERROR.INVALID_ACTION_NEW_ORDER', 'close-circle-outline', true);
                this.router.navigateByUrl(`/${providerIndex}/${providerName}/menu`);
                if(onCreateOrderCallbackFn) {
                    onCreateOrderCallbackFn();
                }
            } else if(response.data.total_created_orders == order.totalCreatedOrders) {
                this.modalService.notification('ERROR.INVALID_ACTION_HEADER', 'ERROR.INVALID_ACTION_PRODUCT_REMOVED', 'close-circle-outline', true, {productName: orderProduct.product_name});
                if(updateOrderInProvider) {
                    this.setInOrders(response.data.order_products, response.data.restaurant_id, response.data.total_created_orders, onDeleteProductCallbackFn);
                } else {
                    if(onDeleteProductCallbackFn) {
                        onDeleteProductCallbackFn();
                    }
                }
                if(response.data.order_products.length == 0) {
                    this.router.navigateByUrl(`/${providerIndex}/${providerName}/menu`);
                }
            } else {
                this.router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.SOMETHING_WENT_WRONG', message: 'ERROR.PRODUCT_NOT_FOUND', goTo: this.backUrl, button: 'BACK'}});
            }
        }, (error: any) => {
            this.router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.SOMETHING_WENT_WRONG', message: 'ERROR.PRODUCT_NOT_FOUND', goTo: this.backUrl, button: 'BACK'}});
        });
    }

    public catchProductBelongsToAGroupError(providerIndex: string, providerName: string, order: Order) {
        this.currentOrderService.getCurrentOrder().subscribe( (response: any) => {
            this.modalService.notification('ERROR.INVALID_ACTION_HEADER', 'ERROR.INVALID_ACTION_PRODUCT_BELONGS_TO_A_GROUP', 'close-circle-outline', true);
            this.router.navigateByUrl(`/${providerIndex}/${providerName}/menu`);
        }, (error: any) => {
            this.router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.SOMETHING_WENT_WRONG', message: 'ERROR.PRODUCT_NOT_FOUND', goTo: this.backUrl, button: 'BACK'}});
        });
    }

    private setInOrders(orderProducts: any, restaurantId, totalCreatedOrders: number, callbackFn: any) {
        const restaurant = this.restaurantService.getByRestaurantId(restaurantId);
        this.initialSetupService.initialSetup.subscribe((initialSetup) => {
            const order = ProviderOrder.initOrder(restaurant.id, restaurant.slug, initialSetup.data.currency, orderProducts, totalCreatedOrders);
            if(callbackFn) {
                callbackFn(order);
            }
            this.providersService.updateOrder(order, ConstantsService.RESTAURANTS_INDEX);
        });
    }
}
