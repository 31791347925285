import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../modal/modal.service';
import {WaiterService} from '../waiter/waiter.service';
import {LoadingService} from '../loading/loading.service';
import {BillsService} from '../bills/bills.service';
import {StorageService} from '../storage/storage.service';
import {BackService} from "../back/back.service";

@Injectable({
    providedIn: 'root'
})
export class ComandaErrorHandlerService {
    private backUrl: string;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private waiterService: WaiterService,
        private loadingService: LoadingService,
        private billService: BillsService,
        private storage: StorageService,
        private backService: BackService
    ) {
        this.backService.backUrl.subscribe(url => {
            this.backUrl = url;
        });
    }


    public handleCurrentBillIsLocked(errorData) {
        if (errorData.is_who_locked_the_bill) {
            this.storage.set('can_unlock_the_bill', 'true');
            if(errorData.can_unlock_the_bill) {
                this.modalService.areYousure('MYSTAY.CURRENT_BILL_IS_LOCKED_HEADER_IS_OWNER', 'MYSTAY.CURRENT_BILL_IS_LOCKED_DESCRIPTION_IS_OWNER', this.unlockBill.bind(this), true, '', null, 'MYSTAY.UNLOCK_BILL', 'GENERAL.OK');
            } else {
                this.modalService.areYousure('MYSTAY.CURRENT_BILL_IS_LOCKED_HEADER_IS_OWNER', 'MYSTAY.CURRENT_BILL_IS_LOCKED_DESCRIPTION_IS_GUEST', this.callWaiter.bind(this), true, '', null, 'MYSTAY.REQUEST_ASSISTANCE', 'GENERAL.OK');
            }

        } else {
            const params = {locked_by: errorData.locked_by.diner_name};
            this.storage.set('can_unlock_the_bill', 'false');
            this.modalService.areYousure('MYSTAY.CURRENT_BILL_IS_LOCKED_HEADER_IS_GUEST', 'MYSTAY.CURRENT_BILL_IS_LOCKED_DESCRIPTION_IS_GUEST', this.callWaiter.bind(this), true, '', null, 'MYSTAY.REQUEST_ASSISTANCE', 'GENERAL.OK', params);
        }
    }

    private async callWaiter(){
        const loadingToken = await this.loadingService.getLoadingIndicator('REQUESTING_ASSISTANCE');
        this.waiterService.assistanceRequest().subscribe( _ => {
            this.modalService.presentToast('ASSISTANCE_REQUEST_SENT', 'A_WAITER_WILL_BE_WITH_YOU_SOON', 'custom-toast-property');
            loadingToken.dismiss();
        }, error => {
            loadingToken.dismiss();
            if (error.status === 425) {
                this.modalService.presentToast('NOTIFICATION_ALREADY_SENT', error.error.data.message, 'custom-toast-property');
            } else {
                this.router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.SOMETHING_WENT_WRONG', message: 'ERROR.ASSISTANCE_REQUEST_ERROR', goTo: this.backUrl , button: 'BACK'}});
            }
        });
    }

    private async unlockBill() {
        const loadingToken = await this.loadingService.getLoadingIndicator('MYSTAY.UNLOCKING_BILL');
        this.billService.unlockBill().subscribe(response => {
            loadingToken.dismiss();
            this.storage.set('is_bill_locked', 'false');
        }, error => {
            loadingToken.dismiss();
            if (error.status == 406) { // Cuenta cerrada
                this.router.navigate(['/errors/generic-error'], {state: {title: 'MYORDER.CURRENT_BILL_CLOSED_TITLE', message: 'MYORDER.CURRENT_BILL_CLOSED_MESSAGE', goTo: this.backUrl, button: 'BACK'}});
            } else if (error.status == 410) { // Estadia cerrada
                this.router.navigate(['/errors/generic-error'], {state: {title: 'MYORDER.CURRENT_RESTAURANT_STAY_CLOSED', message: '', goTo: this.backUrl, button: 'BACK'}});
            }
        });
    }

}
