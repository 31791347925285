import { Injectable } from '@angular/core';
import { AcronymService } from '../acronym/acronym.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private data: any;
  private acronym: string = null;

  public changes = new Subject<string>();

  constructor(acronymService: AcronymService) {
    this.acronym = acronymService.parse();
  }

  get(k: string, def?: any) {
    const v = localStorage.getItem(this.key(k));
    return v !== null ? v : def;
  }

  set(k: string, v: string) {
    localStorage.setItem(this.key(k), v);
    this.changes.next(k);
  }

  remove(k: string) {
    localStorage.removeItem(this.key(k));
    this.changes.next(k);
  }

  key(key: string) {
    return this.acronym + '.' + key;
  }

  has(k: string) {
    const v = localStorage.getItem(this.key(k));
    return v !== null;
  }

  public onChange() {
    return this.changes.asObservable();
  }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }
}
