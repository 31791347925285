import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackService {
  private backUrlSubject = new BehaviorSubject(undefined);
  public backUrl = this.backUrlSubject.asObservable();

  constructor() { }

  async setBackUrl(backUrl) {
    this.backUrlSubject.next(backUrl);
  }

}