import { Injectable } from '@angular/core';
import { GeneralService } from '../general/general.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';

@Injectable({
  providedIn: 'root'
})
export class CurrentOrderService extends GeneralService<any> {
  private token: any;

  constructor(
    protected http: HttpClient,
    protected storage: StorageService,
  ){
    super(http, 'current-order');
    this.token = this.storage.get(ConstantsService.MY_STAY_TOKEN);
    this.buildHeaders();
  }

  addProductToCurrentOrder(product_output_interval_code, amount, notes, product_id) {
    const body = {
      product_output_interval_code: product_output_interval_code,
      amount: amount,
      notes: notes,
      product_id: product_id
    }

    return this.http.post<ApiResponse<any>>(`${this.getApiPath()}/current-order`, body, {headers: this.buildHeaders()})
  }

  getCurrentOrder() {
    if (this.storage.has(ConstantsService.MY_STAY_TOKEN)) {
      const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
      return this.http.get<ApiResponse<any>>(`${this.getApiPath()}/current-order?language_code=${languageCode}`, {headers: this.buildHeaders()}).pipe(shareReplay());
    }
  }

  updateCurrentOrderProduct(product_output_interval_code: string, amount: number, notes: string, product_id: number, temporaryId: string) {
    const body = {
      product_output_interval_code: product_output_interval_code,
      amount: amount,
      notes: notes,
      product_id: product_id,
    }
    return this.http.put<ApiResponse<any>>(`${this.getApiPath()}/current-order/${temporaryId}`, body, {headers: this.buildHeaders()}).pipe(shareReplay());
  }

  deleteCurrentOrderProduct(temporaryId: string) {
    return this.http.delete<ApiResponse<any>>(`${this.getApiPath()}/current-order/${temporaryId}`, {headers: this.buildHeaders()}).pipe(shareReplay());
  }

  refreshCurrentOrder() {
    const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
    return this.http.get<ApiResponse<any>>(`${this.getApiPath()}/refresh-current-order?language_code=${languageCode}`, {headers: this.buildHeaders()}).pipe(shareReplay());
  }

  buildHeaders() {
    const storageToken = this.storage.get(ConstantsService.MY_STAY_TOKEN);
    this.token = storageToken == this.token ? this.token : storageToken;
    // CHHHH: Sin la condición de si existe un token, el set del new headear rompe el header dejándolo vacío porque no carga los atributos como tales sino que quedan en un lazyInit y lazy update 
    if (this.token && (!this.headers.has('bill-diner-token') || this.headers.get('bill-diner-token') !== this.token)) {
      this.headers = new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .set('Bill-diner-token', this.token);
    }
    return this.headers;
  }

}
